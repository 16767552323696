/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

import axios from 'axios'

import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

type Props = {
  className: string
}

const TablesWidget10: React.FC<Props> = ({className}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth, shallowEqual) as UserModel

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${user.accessToken}`, // Include the token as Bearer token in the Authorization header
    },
  }

  function getDay(inputDate: string): string {
    // Parse the input date string as a Date object
    const date: Date = new Date(inputDate)

    // Define months in abbreviated format
    const months: string[] = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    // Get day, month, and year components
    const day: number = date.getDate()
    const month: string = months[date.getMonth()]
    const year: number = date.getFullYear()

    // Format the date in the desired way
    const formattedDate: string = `${day}`

    return formattedDate
  }

  function getMonth(inputDate: string): string {
    // Parse the input date string as a Date object
    const date: Date = new Date(inputDate)

    // Define months in abbreviated format
    const months: string[] = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    // Get day, month, and year components
    const day: number = date.getDate()
    const month: string = months[date.getMonth()]
    const year: number = date.getFullYear()

    // Format the date in the desired way
    const formattedDate: string = `${month}${year}`

    return formattedDate
  }

  function formatDate(inputDate: string): string {
    // Parse the input date string as a Date object
    const date: Date = new Date(inputDate)

    // Define months in abbreviated format
    const months: string[] = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    // Get day, month, and year components
    const day: number = date.getDate()
    const month: string = months[date.getMonth()]
    const year: number = date.getFullYear()

    // Format the date in the desired way
    const formattedDate: string = `${day} ${month} ${year}`

    return formattedDate
  }

  const [workshopList, setWorkshopList] = useState([
    {
      materi: "HOW TO PICK YOUR (RIGHT) INVESTMENT BANKER: SELL-SIDE's POV",
      stock: '30',
      start_date: '2023-11-09',
      end_date: '2023-11-09',
      speaker: 'The Fei Ming',
      trainer: null,
      images:
        'https://fs-media.kontan.co.id/kstore/upload/brand_images/brand_images_10102313515300.jpg',
      link: 'https://store.kontan.co.id/product/how-to-pick-your-right-investment-banker-sell-sides-pov',
      fasilitator: {
        nama: '...',
        jabatan: '...',
        photo: '...',
      },
    },
    {
      materi: 'Business Contract Drafting',
      stock: '5',
      start_date: '2023-11-16',
      end_date: '2023-11-17',
      speaker: 'Ricardo Simanjuntak',
      trainer: '13',
      images:
        'https://fs-media.kontan.co.id/kstore/upload/brand_images/brand_images_19102316124300.jpg',
      link: 'https://store.kontan.co.id/product/business-contract-drafting',
      fasilitator: {
        nama: 'Dr Ricardo Simanjuntak, SH., LL.M., ANZIIF.CIP',
        jabatan: 'Lawyer',
        photo:
          'https://fs-media.kontan.co.id/academy/upload/trainer/170710_Ricardo-Simanjuntak-6.jpg.jpg',
      },
    },
  ])

  //https://api.kontan.co.id/store/kacademy?apikey=kqigkptafawz33c00wtuuosnal7q70c574ayy8sh
  // component did mount
  useEffect(() => {
    /*
      const params = {
        apikey: 'kqigkptafawz33c00wtuuosnal7q70c574ayy8sh'
      };
      */

    var params = new URLSearchParams()
    params.append('email', user.email)
    axios.post(process.env.REACT_APP_API_URL + '/kacademy.php', params, config).then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.products != undefined
      ) {
        setWorkshopList(response.data.products)
      }
    })
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Jadwal Workshop Kontan Academy</span>
          <span className='text-muted mt-1 fw-bold fs-7'>5 workshop terbaru</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='p-2 w-200px'>Fasilitator</th>
                <th className='w-200px'>Materi</th>
                <th className='w-100px'>Link</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {workshopList != undefined &&
                workshopList.length > 0 &&
                workshopList.map((item, idx) => (
                  <tr>
                    {
                      //console.log(item.fasilitator.nama===undefined)
                    }
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='me-3'>
                          {item.fasilitator.photo !== undefined && (
                            <>
                              <img src={item.fasilitator.photo} width='70' />
                            </>
                          )}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a
                            href={item.link}
                            className='text-dark fw-bolder text-hover-primary fs-6'
                            target='_blank'
                          >
                            {item.fasilitator.nama !== undefined && <>{item.fasilitator.nama}</>}
                            {item.fasilitator.nama === undefined && <>{`Kontan Academy`}</>}
                          </a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {item.fasilitator.jabatan !== undefined && (
                              <>{item.fasilitator.jabatan}</>
                            )}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='me-3'>
                          <img src={item.images} alt='' width='70' />
                        </div>
                        <div>
                          <a
                            href={item.link}
                            className='text-dark fw-bolder text-hover-primary d-block'
                            target='_blank'
                          >
                            {item.materi}
                          </a>
                          <span className='text-muted fw-bold text-muted d-block fs-7'>
                            {formatDate(item.start_date) === formatDate(item.end_date) && (
                              <>
                                {formatDate(item.start_date)} (Sisa Tempat: {item.stock})
                              </>
                            )}
                            {formatDate(item.start_date) !== formatDate(item.end_date) &&
                              getMonth(item.start_date) === getMonth(item.end_date) && (
                                <>
                                  {getDay(item.start_date)} - {formatDate(item.end_date)} (Sisa
                                  Tempat: {item.stock})
                                </>
                              )}
                            {formatDate(item.start_date) !== formatDate(item.end_date) &&
                              getMonth(item.start_date) !== getMonth(item.end_date) && (
                                <>
                                  {formatDate(item.start_date)} - {formatDate(item.end_date)} (Sisa
                                  Tempat: {item.stock})
                                </>
                              )}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <a href={item.link} target='_blank'>
                        Visit Kontan Store
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget10}

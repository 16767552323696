/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import taxSvgIcon from './media/taxes-svgrepo-com.svg'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {UserModel} from '../../auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'

interface TextWithBreaksProps {
  text: string
}

const TextWithBreaks = ({text}: TextWithBreaksProps): JSX.Element => {
  const lines = text.split('\n')
  return (
    <div>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== lines.length - 2 && <br />}
        </React.Fragment>
      ))}
    </div>
  )
}

type Props = {
  className: string
  documentType: string
}

const TableCases: React.FC<Props> = ({className, documentType}) => {
  const showFirst = 10
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({auth}) => auth, shallowEqual) as UserModel

  const [pageInfo, setPageInfo] = useState({
    page: 1,
    elements: [1],
    totalPage: 1,
    limitCount: 0,
    totalData: 0,
  })

  const [documentList, setDocumentlist] = useState([
    {
      document_id: 'id-001',
      no_keputusan: '[no_keputusan]',
      jenis_kasus: '[jenis kasus]',
      tahun: '[tahun]',
      pemohon: '[pemohon]',
      keputusan: '[keputusan]',
      document_link: '[document_link]',
    },
  ])

  const [searchTextBox, setSearchTextBox] = useState('')
  const [selectedJenisKasus, setSelectedJenisKasus] = useState('') // State for the combo list
  const [selectedKeputusan, setSelectedKeputusan] = useState('') // State for the combo list
  const [selectedTahun, setSelectedTahun] = useState('') // State for the combo list

  const [isSearching, setIsSearching] = useState(false) // New state for loading status

  const [searchContent, setSearchContent] = useState({
    searchStatus: '',
    searchText: '',
    searchItems: [],
  })

  const [searchContent2, setSearchContent2] = useState({
    searchStatus: '',
    searchText: '',
    searchItems: [],
  })

  useEffect(() => {
    console.log('masuk')
    const params = new URLSearchParams()
    params.append('email', user.email)
    params.append('doctype', documentType)
    params.append('page', '1')
    params.append('jeniskasus', selectedJenisKasus)
    params.append('keputusan', selectedKeputusan)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${user.accessToken}`, // Include the token as Bearer token in the Authorization header
      },
    }

    axios.post(process.env.REACT_APP_API_URL + '/caselist.php', params, config).then((response) => {
      const limitCount = parseInt(response.data.limitcount, 10)
      const totalData = parseInt(response.data.totaldata, 10)
      const totalPage = Math.ceil(totalData / limitCount)
      const elements = Array.from({length: totalPage}, (_, i) => i + 1)

      if (response.data) {
        if (response.data.error) {
          dispatch(auth.logout())
        } else {
          setDocumentlist(response.data.dokumen)
          setPageInfo({
            page: 1,
            elements: elements,
            totalPage: totalPage,
            limitCount: limitCount,
            totalData: totalData,
          })
        }
      }
    })
  }, [documentType])

  const handleKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      doSearch(searchContent.searchText, 1)
    }
  }

  const handleKeyPressSearch2 = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      doSearch(searchContent2.searchText, 1)
    }
  }

  const doSearch = (searchText: string, page: number) => {
    setIsSearching(true)

    const params = new URLSearchParams()
    params.append('email', user.email)
    params.append('doctype', documentType)
    params.append('page', `${page}`)
    params.append('keyword', searchContent.searchText)
    params.append('keyword2', searchContent2.searchText)
    params.append('jeniskasus', selectedJenisKasus)
    params.append('keputusan', selectedKeputusan)
    params.append('tahun', selectedTahun)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${user.accessToken}`,
      },
    }

    axios.post(process.env.REACT_APP_API_URL + '/caselist.php', params, config).then((response) => {
      const limitCount = parseInt(response.data.limitcount, 10)
      const totalData = parseInt(response.data.totaldata, 10)
      const totalPage = Math.ceil(totalData / limitCount)
      const elements = Array.from({length: totalPage}, (_, i) => i + 1)

      setDocumentlist(response.data.dokumen)
      setPageInfo({
        ...pageInfo,
        page,
        totalPage,
        elements,
        limitCount,
        totalData,
      })
      setIsSearching(false)
    })
  }

  const doSearch2 = (searchText: string, page: number) => {
    setIsSearching(true)

    const params = new URLSearchParams()
    params.append('email', user.email)
    params.append('doctype', documentType)
    params.append('page', `${page}`)
    params.append('keyword', searchContent.searchText)
    params.append('keyword2', searchContent2.searchText)
    params.append('jeniskasus', selectedJenisKasus)
    params.append('keputusan', selectedKeputusan)
    params.append('tahun', selectedTahun)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${user.accessToken}`,
      },
    }

    axios.post(process.env.REACT_APP_API_URL + '/caselist.php', params, config).then((response) => {
      const limitCount = parseInt(response.data.limitcount, 10)
      const totalData = parseInt(response.data.totaldata, 10)
      const totalPage = Math.ceil(totalData / limitCount)
      const elements = Array.from({length: totalPage}, (_, i) => i + 1)

      setDocumentlist(response.data.dokumen)
      setPageInfo({
        ...pageInfo,
        page,
        totalPage,
        elements,
        limitCount,
        totalData,
      })
      setIsSearching(false)
    })
  }

  const gotoPage = (searchText: string, page: number) => {
    setIsSearching(true) // Set isSearching to true when navigating pages

    const params = new URLSearchParams()
    params.append('email', user.email)
    params.append('doctype', documentType)
    params.append('page', `${page}`)
    params.append('keyword', searchContent.searchText)
    params.append('keyword2', searchContent2.searchText)
    params.append('jeniskasus', selectedJenisKasus)
    params.append('keputusan', selectedKeputusan)
    params.append('tahun', selectedTahun)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${user.accessToken}`,
      },
    }

    axios.post(process.env.REACT_APP_API_URL + '/caselist.php', params, config).then((response) => {
      const limitCount = parseInt(response.data.limitcount, 10)
      const totalData = parseInt(response.data.totaldata, 10)
      const totalPage = Math.ceil(totalData / limitCount)
      const elements = Array.from({length: totalPage}, (_, i) => i + 1)

      setDocumentlist(response.data.dokumen)
      setPageInfo({
        ...pageInfo,
        page,
        totalPage,
        elements,
        limitCount,
        totalData,
      })
      setIsSearching(false)
    })
  }

  const searchTextChange = (inputTeks: string) => {
    setSearchContent({
      searchStatus: '',
      searchText: inputTeks,
      searchItems: searchContent.searchItems,
    })
  }

  const searchText2Change = (inputTeks: string) => {
    setSearchContent2({
      searchStatus: '',
      searchText: inputTeks,
      searchItems: searchContent.searchItems,
    })
  }

  const renderPagination = () => {
    const {page, totalPage} = pageInfo
    const pageLinks = []

    if (totalPage <= 1) return null

    const addPageLink = (p: number, label?: string) => {
      pageLinks.push(
        <li key={p} className={`page-item${p === page ? ' active' : ''}`}>
          <a
            className='page-link'
            onClick={() => gotoPage(searchContent.searchText, p)}
            style={{cursor: 'pointer'}}
          >
            {label || p}
          </a>
        </li>
      )
    }

    addPageLink(1)

    if (page > 3) {
      pageLinks.push(
        <li key='ellipsis1' className='page-item disabled'>
          <a className='page-link'>...</a>
        </li>
      )
    }

    for (let p = Math.max(2, page - 2); p <= Math.min(totalPage - 1, page + 2); p++) {
      addPageLink(p)
    }

    if (page < totalPage - 2) {
      pageLinks.push(
        <li key='ellipsis2' className='page-item disabled'>
          <a className='page-link'>...</a>
        </li>
      )
    }

    if (totalPage > 1) {
      addPageLink(totalPage)
    }

    return <ul className='pagination'>{pageLinks}</ul>
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <div className='d-flex mb-2'>
            <input
              className='form-control me-2 min-w-300px'
              value={searchContent.searchText}
              onChange={(e) => searchTextChange(e.target.value)}
              onKeyPress={handleKeyPressSearch}
              type='search'
              placeholder='Masukkan nama pihak pemohon atau tergugat'
              aria-label='Search'
            />
            <input
              className='form-control me-2 min-w-300px'
              value={searchContent2.searchText}
              onChange={(e) => searchText2Change(e.target.value)}
              onKeyPress={handleKeyPressSearch2}
              type='search'
              placeholder='Isi dalam kasus'
              aria-label='Search'
            />
            <button
              className='btn btn-sm btn-icon btn-active-color-primary mt-1'
              onClick={() => doSearch(searchContent.searchText, 1)}
            >
              {isSearching ? (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              ) : (
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 16 16'>
                  <path
                    fill='currentColor'
                    fillRule='evenodd'
                    d='M11.5 7a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0Zm-.82 4.74a6 6 0 1 1 1.06-1.06l3.04 3.04a.75.75 0 1 1-1.06 1.06l-3.04-3.04Z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
            </button>
          </div>
          <div className='d-flex mb-2'>
            <select
              className='form-control me-2'
              value={selectedJenisKasus}
              onChange={(e) => setSelectedJenisKasus(e.target.value)}
            >
              <option value=''>Pilih Jenis Kasus</option>
              <option value='Pajak Pertambahan Nilai (PPN)'>Pajak Pertambahan Nilai (PPN)</option>
              <option value='Gugatan Pajak'>Gugatan Pajak</option>
              <option value='Tarif'>Tarif</option>
              <option value='Pajak Penghasilan Pasal 25/29 Badan'>
                Pajak Penghasilan Pasal 25/29 Badan
              </option>
              <option value='Pajak Penghasilan Pasal 26 (PPh.26)'>
                Pajak Penghasilan Pasal 26 (PPh.26)
              </option>
              <option value='Bea Masuk / Nilai Pabean'>Bea Masuk / Nilai Pabean</option>
              <option value='PPN'>PPN</option>
              <option value='Pajak Penghasilan Pasal 23 (PPh.23)'>
                Pajak Penghasilan Pasal 23 (PPh.23)
              </option>
              <option value='Non Tarif dan Non Nilai Pabean (termasuk SPKTNP)'>
                Non Tarif dan Non Nilai Pabean (termasuk SPKTNP)
              </option>
              <option value='Pajak Penghasilan Pasal 4 ayat 2 Final'>
                Pajak Penghasilan Pasal 4 ayat 2 Final
              </option>
              <option value='Pajak Penghasilan Pasal 23/26 Final'>
                Pajak Penghasilan Pasal 23/26 Final
              </option>
              <option value='Pajak Penghasilan Pasal 21 (PPh.21)'>
                Pajak Penghasilan Pasal 21 (PPh.21)
              </option>
              <option value='Gugatan'>Gugatan</option>
              <option value='Pajak Penghasilan Pasal 22 (PPh.22)'>
                Pajak Penghasilan Pasal 22 (PPh.22)
              </option>
              <option value='Bea Keluar'>Bea Keluar</option>
              {/* Add other options here */}
            </select>
            <select
              className='form-control me-2'
              value={selectedKeputusan}
              onChange={(e) => setSelectedKeputusan(e.target.value)}
            >
              <option value=''>Pilih Keputusan</option>
              <option value='Ditolak'>Ditolak</option>
              <option value='Dikabulkan'>Dikabulkan</option>
              <option value='Dikabulkan Seluruhnya'>Dikabulkan Seluruhnya</option>
              <option value='Dikabulkan Sebagian'>Dikabulkan Sebagian</option>
              {/* Add other options here */}
            </select>
            <select
              className='form-control me-2'
              value={selectedTahun}
              onChange={(e) => setSelectedTahun(e.target.value)}
            >
              <option value=''>Pilih Tahun</option>
              <option value='2019'>2019</option>
              <option value='2020'>2020</option>
              <option value='2021'>2021</option>
              <option value='2022'>2022</option>
              <option value='2023'>2023</option>
              <option value='2024'>2024</option>
              {/* Add other options here */}
            </select>
          </div>
          <span className='text-muted mt-1 fw-bold fs-7 ms-2'>
            Terdapat {pageInfo.totalData} putusan pengadilan sebagai hasil filter
          </span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-300px rounded-start'>No Keputusan</th>
                <th className='min-w-200px'>Jenis Kasus</th>
                <th className='min-w-100px'>Tahun</th>
                <th className='min-w-200px'>Pihak-pihak</th>
                <th className='min-w-200px'>Keputusan</th>
              </tr>
            </thead>
            <tbody>
              {documentList &&
                documentList.length > 0 &&
                documentList.map((itemDocument, idxDocument) => (
                  <tr key={'dokumenAturan' + idxDocument}>
                    <td className='align-top ps-2'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a
                            href={`https://setpp.kemenkeu.go.id/risalah/ambilFileDariDisk/${itemDocument.document_id}`}
                            target='_blank'
                          >
                            {itemDocument.no_keputusan}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className='align-top'>{itemDocument.jenis_kasus}</td>
                    <td className='align-top'>{itemDocument.tahun}</td>
                    <td className='align-top'>
                      <TextWithBreaks text={itemDocument.pemohon} />
                    </td>
                    <td className='align-top'>{itemDocument.keputusan}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='d-flex flex-stack flex-wrap pt-2 pb-5'>
          <div className='fs-6 fw-bold text-gray-700'>
            Showing{' '}
            {Number.isNaN((pageInfo.page - 1) * pageInfo.limitCount + 1)
              ? 0
              : (pageInfo.page - 1) * pageInfo.limitCount + 1}{' '}
            to{' '}
            {Number.isNaN(pageInfo.totalPage)
              ? 0
              : pageInfo.page === pageInfo.totalPage
              ? pageInfo.totalData
              : pageInfo.page * pageInfo.limitCount}{' '}
            of {Number.isNaN(pageInfo.totalData) ? 0 : pageInfo.totalData} entries
          </div>
          {renderPagination()}
        </div>
      </div>
    </div>
  )
}

export {TableCases}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'

import {connect} from 'react-redux'
import * as auth from '../redux/AuthRedux'
import {UserModel} from '../models/UserModel'
//import {RootState, AppDispatch, deselectNote} from '../../../../setup'
import {RootState} from '../../../../setup'

import clsx from 'clsx'

//import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'

//import {login} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function LoginForm(props: any) {
  //Untuk monitor perubahan state dari user
  const user: UserModel = useSelector<RootState>(({auth}) => auth, shallowEqual) as UserModel

  const [loginLoading, setLoginLoading] = useState(false)

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    //onSubmit: (values, {setStatus, setSubmitting}) => {

    onSubmit: (values, {setSubmitting}) => {
      setLoginLoading(true)

      dispatch({type: auth.actionTypes.Reset}) //Reset session auth ke initial state

      const dataLogin: UserModel = {
        email: values.email,
        password: values.password,
        jumlahAkses: 0,
        sisaHari: 0,
        memberSince: '',
      }
      dispatch({type: auth.actionTypes.Login, payload: dataLogin}) //Lakukan proses login
    }, //onSumbit
  }) //useFormik

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Call handleChange to update form values
    formik.handleChange(event)
    // Reset status to empty string
    formik.setStatus('')
  }

  useEffect(() => {
    setLoginLoading(false)
    formik.setSubmitting(false)
    if (user.lastLoginStatus === 'error') {
      formik.setStatus('Login error')
    }
  }, [user, formik.setSubmitting])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to Pajak/Legal Tools</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
        /*
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
         */
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Account</label>
        <input
          placeholder='Masukkan akun email Anda'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          onChange={handleFieldChange} // Using custom handleFieldChange
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/*
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
            */}
            {/* end::Link */}
          </div>
        </div>
        <input
          placeholder='Masukkan password Anda'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          onChange={handleFieldChange} // Using custom handleFieldChange
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loginLoading && <span className='indicator-label'>Continue</span>}
          {loginLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <div className='mt-3 text-center'>
          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-6'>
            <Link
              to='/auth/forgot-password'
              className='link-primary fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password
            </Link>
            &nbsp; |
            <Link
              to='/auth/registration'
              className='link-primary fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Sign Up Now
            </Link>
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Separator */}
      </div>
      {/* end::Action */}
    </form>
  )
} //LoginForm

export const Login = connect(null, auth.AuthSlice.actions)(LoginForm)

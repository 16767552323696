import axios, {AxiosResponse} from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {UserRegistrationStatusModel} from '../models/UserRegistrationStatusModel'

axios.defaults.withCredentials = true

const API_URL = process.env.REACT_APP_API_URL || 'api'

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
//export const LOGIN_URL = `${API_URL}/auth/login`
export const LOGIN_URL = `${API_URL}/login.php`
export const LOGOUT_URL = `${API_URL}/logout.php`
export const REGISTER_URL = `${API_URL}/register.php`
export const REQUEST_PASSWORD_URL = `${API_URL}/resetrequest.php`
export const RESET_PASSWORD_URL = `${API_URL}/resetpassword.php`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/getuser.php`

//Ini adalah kumpulan function axios.post yang masih penting ada

// Server should return UserModel
export function userLogin(email: string, password: string) {
  //this is for php
  //Testing saja
  var params = new URLSearchParams()
  params.append('email', email)
  params.append('password', password)

  return axios.post<UserModel>(LOGIN_URL, params, {
    withCredentials: true, // Set the withCredentials option to true
  })
}

// Server should return UserRegistrationStatusModel
export function userRegister(
  email: string,
  firstname: undefined | string,
  lastname: undefined | string,
  password: string
) {
  var params = new URLSearchParams()
  params.append('email', email)
  params.append('password', password)
  params.append('firstname', firstname ?? '')
  params.append('lastname', lastname ?? '')

  return axios.post<UserRegistrationStatusModel>(REGISTER_URL, params, {
    withCredentials: true, // Set the withCredentials option to true
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  var params = new URLSearchParams()
  params.append('email', email)

  //return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
  return axios.post<{success: boolean; message: string}>(REQUEST_PASSWORD_URL, params)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function updatePassword(token: string, email: string, password: string) {
  var params = new URLSearchParams()
  params.append('token', token)
  params.append('email', email)
  params.append('password', password)

  //return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
  return axios.post<{success: boolean; message: string}>(REQUEST_PASSWORD_URL, params)
}

export function getUserByToken(email: string, token: string) {
  //this is for php
  var params = new URLSearchParams()
  params.append('email', email)
  //params.append('token', token);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`, // Include the token as Bearer token in the Authorization header
    },
  }

  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, params, config)
  //return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'
import axios from 'axios'
import svgNotes from '../../../../app/modules/profile/components/projects/notes-science-svgrepo-com.svg'

import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

//import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  jumlahCatatan: number
}

const ListCatatanTerbaru: React.FC<Props> = ({className, jumlahCatatan}) => {
  const user: UserModel = useSelector<RootState>((state) => state.auth, shallowEqual) as UserModel
  //const user: UserModel = useSelector<RootState>(({auth}) => auth, shallowEqual) as UserModel

  const [noteList, setNotelist] = useState([
    {
      catatan_id: 1,
      nama_catatan: 'Loading..',
      creation_date: 'Loading...',
      last_update: 'Loading...',
      deskripsi: 'Loading...',
    },
  ])

  const [searchTextBox, setSearchTextBox] = useState('')

  useEffect(() => {
    var params = new URLSearchParams()
    params.append('email', user.email)

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${user.accessToken}`, // Include the token as Bearer token in the Authorization header
      },
    }

    axios.post(process.env.REACT_APP_API_URL + '/notelist.php', params, config).then((response) => {
      setNotelist(response.data.catatan)
      //console.log(response.data);
    })
  }, [])

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8 shadow'>
      {/* begin::Header */}
      <div className='card-header align-items-center border-1 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>
            <img src={svgNotes} width={25} className='me-2' />
            Smart Notes Terbaru
          </span>
          <span className='text-muted fw-bold fs-7'>Total: {jumlahCatatan} Catatan</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-0 mt-7'>
        {noteList.length > 0 &&
          noteList.map((item, idx) => (
            <>
              {/* begin::Item */}
              <div
                key={item.catatan_id}
                className={`d-flex align-items-center bg-light-${
                  idx % 4 == 0
                    ? 'warning'
                    : idx % 4 == 1
                    ? 'success'
                    : idx % 4 == 2
                    ? 'danger'
                    : 'info'
                } rounded p-5 mb-6`}
              >
                {/* begin::Icon */}
                <span
                  className={`svg-icon svg-icon-${
                    idx % 4 == 0
                      ? 'warning'
                      : idx % 4 == 1
                      ? 'success'
                      : idx % 4 == 2
                      ? 'danger'
                      : 'info'
                  } me-5`}
                >
                  <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                </span>
                {/* end::Icon */}
                {/* begin::Title */}
                <div key={`catatanke2-${idx}`} className='flex-grow-1 me-2'>
                  <Link
                    to={`/notes/${item.catatan_id}`}
                    className='fw-bolder text-gray-800 text-hover-primary fs-6'
                  >
                    {item.nama_catatan}
                  </Link>
                  <span className='text-muted fw-bold d-block'>
                    Update Terakhir: {item.last_update}
                  </span>
                </div>
                {/* end::Title */}
                {/* begin::Lable */}
                <span className='fw-bolder text-warning py-1'></span>
                {/* end::Lable */}
              </div>
              {/* end::Item */}
            </>
          ))}
        <div className='d-flex flex-row-reverse col-md-12 col-xl-12 mt-auto mb-3'>
          <Link className='btn btn-primary btn-sm mt-2 mb-0' to='/notes'>
            Lihat Selengkapnya
          </Link>
        </div>
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListCatatanTerbaru}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const RegistrationPending: React.FC = () => {
  /*
  const history = useHistory()
  const redirectToDashboard = () => {
    history.push('/')
  }
  */

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Registration Pending</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4 mt-10'>
          Please check your email to confirm and verify your account to complete the registration!
        </div>
        {/* end::Link */}
      </div>
      <div className='text-center'>
        <Link to='/auth/login'>
          <button type='button' className='btn btn-lg btn-light-primary fw-bolder'>
            Back to Login Page
          </button>
        </Link>
      </div>
    </div>
  )
}

export {RegistrationPending}

import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'

const AuthInit: FC = ({children}) => {
  const didRequest = useRef(false)

  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const accessToken = useSelector<RootState, string | null>((state) => {
    return state.auth.accessToken ? state.auth.accessToken : null
  }, shallowEqual)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          console.log('disiningapain?')
          /*
          const {data: user} = await getUserByToken(props.auth==undefined? "" : props.auth.user==undefined? "" : props.auth.user.email);

          dispatch(props.fulfillUser(user))
          */
        }
      } catch (error) {
        //console.error(error)
        if (!didRequest.current) {
          //dispatch(props.logout())
          dispatch(auth.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(auth.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, []) //end of useEffect

  //return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

//export default connector(AuthInit)
export default AuthInit

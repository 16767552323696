/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'

import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {UserModel} from '../../../app/modules/auth/models/UserModel'

import {RootState, AppDispatch, deselectNote} from '../../../setup'

import {TypedUseSelectorHook, useDispatch, shallowEqual, useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'

import {
  ListAturanTerbaru,
  ListCatatanTerbaru,
  TablesWidget10,
} from '../../../_metronic/partials/widgets'
import {Link} from 'react-router-dom'

const DashboardPage: FC = () => {
  const [daftarAturanTerbaru, setDaftarAturanTerbaru] = useState(null)
  const [noteList, setNoteList] = useState({
    limitcount: 0,
    totaldata: 0,
  })
  const user: UserModel = useSelector<RootState>((state) => state.auth, shallowEqual) as UserModel

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${user.accessToken}`, // Include the token as Bearer token in the Authorization header
    },
  }

  //const note = useAppSelector((state) => state.notes);

  //const dispatch = useAppDispatch();

  //const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
  const noteStore = useSelector<RootState>((state) => state.notes)
  const noteDispatch: AppDispatch = useDispatch()

  useEffect(() => {
    var params = new URLSearchParams()
    params.append('email', user.email)

    axios
      .post(process.env.REACT_APP_API_URL + '/getaturan.php', params, config)
      .then((response) => {
        setDaftarAturanTerbaru(response.data)
      })

    axios.post(process.env.REACT_APP_API_URL + '/notelist.php', params, config).then((response) => {
      setNoteList(response.data)
    })
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className={`card shadow pb-5`}>
        <div className='card-header align-items-center border-1 mt-4 col-md-12 col-xl-12'>
          <div className='row g-5 g-xxl-8 col-md-12 col-xl-12'>
            <div className='col-md-3 col-xl-2'>
              <h2>
                <img src={user.pic} style={{borderRadius: '10%'}} width='150px' height='150px' />
              </h2>
            </div>
            <div className='col-md-9 col-xl-10 pt-2'>
              <h1>
                {user.firstname} {user.lastname} &nbsp;
                <KTSVG
                  path='/media/icons/duotune/general/gen026.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              </h1>
              <h5 className='mt-4 text-black-50'>
                Member sejak: {user.memberSince}
                <br />
                Status: Aktif | Update dokumen terbaru lagi dilakukan, Anda mungkin menemukan
                beberapa konten belum dapat diakses pada pencarian di catatan
              </h5>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen055.svg'
                          className='svg-icon-2 svg-icon-success me-2'
                        />
                        <div className='fs-2 fw-bolder'>{noteList.totaldata}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Jumlah Catatan</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen005.svg'
                          className='svg-icon-2 svg-icon-primary me-2'
                        />
                        <div className='fs-2 fw-bolder'>{user.jumlahAkses}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Kuota Akses Dokumen</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen007.svg'
                          className='svg-icon-2 svg-icon-danger me-2'
                        />
                        <div className='fs-2 fw-bolder'>{user.sisaHari}</div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Sisa Hari Langganan</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <Link to='/feedback'>
                        <button
                          type='button'
                          id='kt_login_signup_form_cancel_button'
                          className='btn btn-lg btn-light-primary w-100 mt-0'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='1.5em'
                            height='1.5em'
                            viewBox='0 0 2048 2048'
                          >
                            <path
                              fill='currentColor'
                              d='M958 1328q101 40 184 106t142 153t91 187t33 210v64h-128v-64q0-119-45-224t-124-183t-183-123t-224-46q-119 0-224 45t-183 124t-123 183t-46 224v64H0v-64q0-109 32-210t92-187t142-152t184-107q-45-31-81-72t-61-88t-38-100t-14-108q0-93 35-174t96-142t142-96t175-36q93 0 174 35t142 96t96 142t36 175q0 55-13 107t-39 100t-61 89t-81 72m-254-48q66 0 124-25t101-68t69-102t26-125t-25-124t-69-101t-102-69t-124-26t-124 25t-102 69t-69 102t-25 124t25 124t68 102t102 69t125 25M2048 0v1024h-256l-384 384v-384h-128V896h256v203l203-203h181V128H640v230q-32 4-64 10t-64 18V0z'
                            />
                          </svg>
                          <br />
                          Request Aturan Pajak
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-5 g-xl-8 mt-0'>
        <div className='col-xxl-6'>
          <ListAturanTerbaru className='card-xxl-stretch' />
        </div>
        <div className='col-xxl-6'>
          <ListCatatanTerbaru
            className='card-xl-stretch mb-xl-8'
            jumlahCatatan={+noteList.totaldata}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

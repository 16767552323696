/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
//import {KTSVG} from '../../../helpers'
//import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'

export function AsideMenuMain() {
  //const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()

  const logout = () => {
    dispatch(auth.logout())
  }

  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Account Kontan Academy
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/layouts/lay001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
        onClickHandler={undefined}
      />
      <AsideMenuItem
        to='/notes'
        icon='/media/icons/duotune/text/txt010.svg'
        title='Catatan Riset'
        fontIcon='bi-layers'
        onClickHandler={undefined}
      />
      <AsideMenuItem
        to='/documents'
        icon='/media/icons/duotune/general/gen054.svg'
        title='Koleksi Aturan'
        fontIcon='bi-layers'
        onClickHandler={undefined}
      />
      <AsideMenuItem
        to='/kasuspajak'
        icon='/media/icons/duotune/general/gen054.svg'
        title='Koleksi Putusan Pengadilan'
        fontIcon='bi-layers'
        onClickHandler={undefined}
      />
      <AsideMenuItem
        to='/subscription'
        icon='/media/icons/duotune/ecommerce/ecm001.svg'
        title='Subscription'
        fontIcon='bi-layers'
        onClickHandler={undefined}
      />
      <AsideMenuItem
        to='/feedback'
        icon='/media/icons/duotune/communication/com003.svg'
        title='Feedback'
        fontIcon='bi-layers'
        onClickHandler={undefined}
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Kontan Academy</span>
        </div>
      </div>
      <AsideMenuItem
        to='/jadwal'
        icon='/media/icons/duotune/general/jadwal-kalender.svg'
        title='Jadwal Workshop'
        fontIcon='bi-layers'
        onClickHandler={undefined}
      />
      <AsideMenuItem
        to='/logout'
        icon='/media/icons/duotune/arrows/arr-logout.svg'
        title='Logout'
        fontIcon='bi-layers'
        onClickHandler={() => logout()}
      />
    </>
  )
}

import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Catatan Riset' to='/notes' />

      <MenuItem title='Koleksi Aturan' to='/documents' />

      <MenuItem title='Koleksi Putusan Pengadilan' to='/kasuspajak' />

      <MenuItem title='Jadwal Workshop' to='/jadwal' />

      {/*}
      <MenuInnerWithSub
        isMega={true}
        title='Jadwal Workshop Kontan Academy'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub>
      */}
    </>
  )
}

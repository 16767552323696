import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as auth from '../../app/modules/auth'

import noteSlice, {
  addNote,
  deleteNote,
  editNote,
  selectNote,
  deselectNote,
} from '../../app/modules/notes/redux/NotesSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

export const combinedReducers = combineReducers({
  auth: auth.AuthSlice.reducer,
  notes: noteSlice.reducer,
})

export const rootReducer = persistReducer(persistConfig, combinedReducers)

// ReturnType is a utility type in TypeScript that is used to extract the return type of a function.
// It is part of the utility types provided by TypeScript to manipulate and query types.
export type RootState = ReturnType<typeof rootReducer>

export {addNote, deleteNote, editNote, selectNote, deselectNote}

export function* rootSaga() {
  yield all([auth.saga()])
}

import {Reducer} from 'redux'
import {Action} from '@reduxjs/toolkit'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
//import {persistReducer} from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
//import {put, takeLatest} from 'redux-saga/effects'

export interface NoteActionWithPayload<T> extends Action {
  payload?: T
}

/*
export const noteActionTypes = {
  AddNote: '[Add Note] Action',
  DeleteNote: '[Delete Note] Action',
  EditNote: '[Edit Note] Action',

  AddNotePart: '[Add Note Part] Action',
  DeleteNotePart: '[Delete Note Part] Action',
  EditNotePart: '[Edit Note Part] Action',

  SelectNote: '[Select Note] Action', // Action to select a note
  DeselectNote: '[Deselect Note] Action', // Action to deselect the current note
}
*/

export interface INote {
  noteId: string
  noteName: string
}

export interface INoteState {
  notes: INote[] // Array of notes
  selectedNote: INote | null // Details of the selected note or null if none is selected
}

export const initialNoteState: INoteState = {
  notes: [],
  selectedNote: null,
}

const noteSlice = createSlice({
  name: 'counter',

  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initialNoteState,

  reducers: {
    addNote: (state, action: PayloadAction<INote>) => {
      state.notes.push(action.payload)
    },

    deleteNote: (state, action: PayloadAction<string>) => {
      state.notes = state.notes.filter((note) => note.noteId !== action.payload)
    },

    editNote: (state, action: PayloadAction<INote>) => {
      // Implement logic to edit a note
    },

    selectNote: (state, action: PayloadAction<INote | null>) => {
      state.selectedNote = action.payload
    },

    deselectNote: (state) => {
      state.selectedNote = null
    },

    /*
    addNote: (state, action: PayloadAction<INote>) => {
      state.notes.push(action.payload)
    },

    deleteNote: (state, action: PayloadAction<string>) => {
      state.notes = state.notes.filter((note) => note.noteId !== action.payload)
    },

    editNote: (state, action: PayloadAction<INote>) => {
      // Implement logic to edit a note
    },

    selectNote: (state, action: PayloadAction<INote | null>) => {
      state.selectedNote = action.payload
    },

    deselectNote: (state) => {
      state.selectedNote = null
    },
    */
  },
})

export default noteSlice

// Export actions
export const {addNote, deleteNote, editNote, selectNote, deselectNote} = noteSlice.actions

// Export reducers
//const noteReducer: Reducer = noteSlice.reducer;
